import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default function Hero() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "images/Contact Page/Rectangle 7.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="section"
      className="contact-banner page-hero reset_password__hero"
      fluid={imageData}
      backgroundColor={`#303d4e`}
    >
      <div className="container"></div>
    </BackgroundImage>
  );
}
