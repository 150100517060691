import React from "react";
import { useToggle } from "@react-md/utils";

import Layout from "../components/Layout/Layout";
import Hero from "../components/RequestPasswordReset/Hero";
import RequestPasswordResetForm from "../components/RequestPasswordReset/RequestPasswordResetForm";

import "../styles/pages/requestPasswordReset.scss";
import "../styles/components/Layout/layout.scss";
import "../styles/pages/responsiveness.scss";

export default function RequestPasswordReset(props) {
  const [visible, enable, disable] = useToggle(true);
  return (
    <Layout>
      <Hero enable={!disable} />
      <div className="spacer"></div>
      <RequestPasswordResetForm
        visible={visible}
        disable={disable}
        enable={enable}
      />
    </Layout>
  );
}
