import React from "react";
import { TextField, Button } from "react-md";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import Parser from "html-react-parser";
import { getDeltaMathAPI } from "../../utilities/utilities";

export default function RequestPasswordResetForm(props) {
  const [submitted, setSubmitted] = React.useState(false);
  const { register, handleSubmit, errors, setError, reset } = useForm();

  const [email, setEmail] = React.useState("");
  const [responseMessage, setResponseMessage] = React.useState("");
  // this response message is relevant when DM staff are helping with password resets
  const [responseMessageDatabase, setResponseMessageDatabase] =
    React.useState("");
  const [customerServiceToken, setCustomerServiceToken] = React.useState("");

  const onSubmit = async (data) => {
    try {
      // hit endpoint to reset password
      let postDomain = getDeltaMathAPI();
      const body = {
        send_request: true,
        email,
      };

      if (
        typeof window !== "undefined" &&
        localStorage.getItem("customer_service_token")
      ) {
        // window type check needed because window is undefined in build process
        body.customer_service_token = localStorage.getItem(
          "customer_service_token"
        ); // see https://github.com/gatsbyjs/gatsby/issues/14480 for more information
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      fetch(postDomain + "/password_reset", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            setResponseMessage(data.message);
            setResponseMessageDatabase("");
          } else if (data.message_database) {
            setResponseMessage("");
            setResponseMessageDatabase(data.message_database);
            setSubmitted(true);
            reset();
          } else {
            setResponseMessageDatabase("");
            setResponseMessage("");
            setSubmitted(true);
            reset();
          }
        });
    } catch (error) {
      setSubmitted(false);
      setError("submit", {
        type: "manual",
        message:
          "There was an error requesting a password reset. Please try again.",
      });
    }
  };

  const showSubmitError = (msg) => {
    return (
      <div className="validation-alert msg-error">
        <p>{msg.message}</p>
      </div>
    );
  };

  const showResetRequestSuccess = (
    <div className="msg-confirm">
      <h2 className="subtitle">Success</h2>
      {responseMessageDatabase.length > 0 ? (
        <div>{Parser(responseMessageDatabase)}</div>
      ) : (
        <p>
          Password reset instructions have been sent to the provided email
          address.
        </p>
      )}
      <Button
        onClick={() => {
          if (typeof window !== "undefined") navigate("/");
        }}
        className="outline-primary-btn"
        theme="primary"
        themeType="outline"
      >
        Okay
      </Button>
    </div>
  );

  const closeModal = () => {
    if (typeof window !== "undefined") navigate("/");
  };

  return (
    <Dialog
      id="request-reset-password-form-dialog"
      visible={props.visible}
      onRequestClose={closeModal}
      aria-labelledby="dialog-title"
      className="passwordFormDialog form-dialog"
    >
      <span className="sr-only" id="dialog-title">
        Reset password dialog
      </span>
      <DialogFooter>
        <VscChromeClose
          className="dialog-close1"
          id="dialog-close"
          onClick={closeModal}
        />
      </DialogFooter>
      <DialogContent className="password-form-content">
        <Card
          className="modalTeamCard"
          id="password-form-dialog-toggle"
          onClick={props.enable}
        >
          <CardHeader className="teamCard-text">
            <CardTitle className="teamCard-text-name">
              Request password reset
            </CardTitle>
          </CardHeader>
          <CardContent>
            {submitted ? (
              showResetRequestSuccess
            ) : (
              <form
                className="update-password-form container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="column">
                    <TextField
                      id="email-text-field"
                      name="emailTextField"
                      type={
                        typeof window !== "undefined" &&
                        localStorage.getItem("customer_service_token")
                          ? "text"
                          : "email"
                      }
                      label="Email"
                      ref={register({
                        required: "A valid email address is required.",
                      })}
                      onChange={(e) => setEmail(e.target.value)}
                    ></TextField>
                  </div>
                </div>
                <div className="row submit-row">
                  <div className="column">
                    <Button
                      className="form-submit-btn"
                      themeType="contained"
                      type="submit"
                    >
                      Send Request
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    {responseMessage.length > 0 && (
                      <div className="validation-alert msg-error response-message">
                        <p>{responseMessage}</p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
